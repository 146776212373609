#page {
  &.js-waitting {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
}

.remove-border-radius *:not(.pace-activity, .nivo-prevNav, .nivo-nextNav) {
  border-radius: 0 !important;
}
.remove-box-shadow * {
  box-shadow: none !important;
}

.page-loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  display: none;
  .page-loading-backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
  }
  .uil-spin-css {
    transform: scale(0.5);
  }
  &.main-product-details-loading, &.cart-overview-loading {
    position: absolute;
    z-index: 100;
    .uil-spin-css {
      transform: scale(0.25);
    }
  }
  &.add-to-cart-loading, &.quickview-loading {
    position: absolute;
    z-index: auto;
    .uil-spin-css {
      transform: scale(0.125);
    }
  }
}

.js-hidden {
  display: none !important;
}

/*** HEADER ***/
#header {
  background: $header-background;

  .header-event-banner {
    font-size: 0.9em;
      letter-spacing: 0.4px;
    .container {
      position: relative;
    }
    .header-event-banner-wrapper {
        [class^="col-"] {
            align-items: center;
        }
    }
    p {
      margin: 0;
      text-align: center;
    }
    a {
      color: inherit;
        display: inline-flex;
        //padding: 8px 5px;
        padding: 4px;
        align-items: baseline;
      //border-bottom: 1px solid;
        
    }
    .close-banner {
      position: absolute;
      right: 15px;
      bottom: 8px;
      cursor: pointer;
    }
      .text-right {
          justify-content: flex-end;
//          a {
//              height: 100%;
//          }
          a:hover {
            background: rgba(250,250,250, 0.3);
        }
      }
      @include media-breakpoint-down(md) {
      .text-right, .text-left {
          text-align: center !important;
      }
      }
      i {
          width: 20px;
          height: 20px;
          line-height: 20px;
          margin-right: 5px;
      }
      a[href^="tel"] {
          strong {
              font-size: 1.3em;
              padding: 0 5px;
          }
          i {
              border: solid 1px;
              border-radius: 50%;
                
          }
          &:hover i {
              background: #fff;
              color: $color_cta;
              border-color: transparent;
              }
      }
  }

  .header-nav {
    .header-nav-wrapper {
      .left-nav, .right-nav {
        > * {
          margin-top: 9px;
        }
      }
      @include media-breakpoint-down(sm) {
        //min-height: 35px;
        //margin-right: 100px;
      }
    }
  }
  .header-phone {
    p {
      margin: 0;
    }
    .material-icons, .fa {
      margin-top: -3px;
      font-size: 13px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        white-space: nowrap;
        margin-right: 12px;
      }
    }
  }

  .main-header {
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    background: inherit;
    .container {
      background: inherit;
    }
    .header-wrapper {
      position: relative;
      background: inherit;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  .header-logo {
    max-width: 30%;
    z-index: 20;
    flex: none;
    padding: 7px 0;
    svg, img {
      max-width: 100%;
      width: auto;
      height: auto;
      vertical-align: middle;
    }
    @include media-breakpoint-only(md) {
      max-width: 40%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      max-width: none;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  .header-right {
    width: 100%;
    margin: 7px 0;
    background: inherit;
    @include media-breakpoint-down(sm) {
      margin: 0;
      padding-top: 6px;
      padding-bottom: 6px;
      min-height: 50px;
      display: flex;
      align-items: center;
    }
  }
  .display-top {
    > div {
      margin-left: 2%;
    }
    @include media-breakpoint-down(sm) {
      flex: 1;
    }
  }

  .header-bottom {
    position: relative;
    background: $wrapper-background;
  }
  .header-main-menu-wrapper {
    position: relative;
    @include media-breakpoint-up(md) {
      min-height: 50px;
    }
  }
  .left-nav-trigger {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    .left-nav-icon {
      //width: 50px;
      height: 100%;
      text-align: center;
      background: $light-background;
      color: $link-color;
      padding: 0 10px;
      cursor: pointer;
      user-select: none;
        //font-family: "Montserrat", sans-serif;
        color: #00658b;
        font-size: 1.3rem;
        font-weight: 600;
        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }
        
        i {
            font-size: 30px;
        }
      &:hover {
        color: $link-hover-color;
      }
    }
  }
  .sticky-icon-cart {
    position: absolute;
    right: 0;
    top: 8px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    .cart-link {
      display: block;
      border-radius: 3px;
      padding: 7px;
      font-size: 20px;
      position: relative;
      white-space: nowrap;
      transition: all 0.3s;
      .cart-total-value {
        display: none;
      }
      .material-icons {
        margin-top: 0;
        display: block;
      }
    }
    .cart-products-count {
      display: block;
      text-align: center;
      width: 16px;
      height: 16px;
      line-height: 16px;
      background-color: $color_pink;
      border-radius: 50%;
      font-size: 10px;
      position: absolute;
      top: -6px;
      left: 9px;
      transition: all 0.3s;
    }
  }

  .mobile-menu {
    margin-left: 0.25rem;
  }
  .mobile-menu-icon {
    cursor: pointer;
    user-select: none;
    height: $base-height;
    width: $base-height;
    font-size: 21px;
    border: 1px solid $border-color;
    border-radius: 3px;
  }
  .mobile-menu-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .mobile-trigger-wrapper {
    flex: none;
  }
  .mobile-left-nav-trigger {
    .left-nav-icon {
      height: $base-height;
      width: $base-height;
      font-size: 24px;
      border-radius: 3px;
      transition: all 0.3s;
      cursor: pointer;
      user-select: none;
      background: $btn-primary-bg;
      color: $btn-primary-color;
      &:hover {
        background: $btn-primary-bg-hover;
      }
    }
  }
}
.desktop-sticky-wrapper {
  //SOLEDIS: 09/03/2021: ombre
  box-shadow:0 8px 8px 0 rgba(0,0,0,.1);
  position: relative;
  z-index: 20;
  &.is-sticky {
    [data-sticky-menu] {
      z-index: 9999;
      //box-shadow: 0 1px 5px $box-shadow-color;
    }
  }
}

#header .is-sticky .sticky-icon-cart {
  opacity: 1;
  visibility: visible;
}
.desktop-sticky-wrapper {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
.mobile-sticky-wrapper {
  background: inherit;
  &.is-sticky {
    [data-mobile-sticky] {
      z-index: 1000;
    }
    .sticky-background {
      position: absolute;
      left: -100%;
      right: -100%;
      bottom: 0;
      height: 55px;
      background: inherit;
      //SOLEDIS: 09/03/2021: ombre
      box-shadow:0 8px 8px 0 rgba(0,0,0,.1);
      //box-shadow: 0 1px 5px $box-shadow-color;
      z-index: -1;
      .boxed-layout & {
        left: -15px;
        right: -15px;
      }
    }
  }
}


/*** WRAPPER ***/
#wrapper {
  background: $wrapper-background;
  padding-bottom: 20px;
  @if not $center-enable-background {
    #index &, #cms & {
      overflow-x: hidden;
    }
  }
}
.main-content {
  .column-wrapper {
    #index & {
      margin-top: 10px;
    }
  }
  .center-wrapper {
  }
}
.mobile-wrapper-reverse {
  display: flex;
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    .sidebar-column {
      margin-top: 25px;
    }
  }
}
.breadcrumb-wrapper {
  background: $breadcrumb-background;
  margin-bottom: 25px;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    background: transparent;
    margin-bottom: 10px;
    .breadcrumb {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      touch-action: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .breadcrumb-item {
        font-size: 0.75rem;
        padding: 0 0.125rem;
        white-space: nowrap;
        .separator {
          width: 1.25rem;
        }
      }
      
    }
  }
}
.breadcrumb {
  margin: 0;
  padding: 15px 0;
  background: transparent;
  .breadcrumb-item {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    h3 {
      font-size: 0.875rem;
      color: inherit;
      margin-bottom: 0;
      margin-top: 0;
    }
    .material-icons {
      font-size: 1rem;
    }
    .separator {
      width: 1.5rem;
    }
    + .breadcrumb-item:before {
      display: none;
    }
  }
  &:not([data-depth="1"]) {
    .breadcrumb-item:first-child {
      span {
        display: none;
      }
    }
  }
  #index &, #category.category-depth-level-1 & {
    display: none;
  }
}

/*** MAIN ***/
#notifications {
  ul {
    list-style: square;
    padding-left: 20px;
  }
  a {
    border-bottom: 1px solid;
  }
    
}

#main {
  .page-header {
    margin-bottom: 25px;
  }
  .page-content {
    margin-bottom: 25px;
      padding: 20px;
      background: $color_white;
    #notifications {
      padding-top: 0;
      margin-left: ($grid-gutter-width / -2);
      margin-right: ($grid-gutter-width / -2);
      @include media-breakpoint-down(md) {
        margin-left: ($grid-gutter-width-small / -2);
        margin-right: ($grid-gutter-width-small / -2);
      }
    }
  }
  .page-footer {
    margin-bottom: 15px;
  }
}

/*** FOOTER ***/
#footer {
    font-size: 0.9em;
  background-color: $footer-background;
  color: $footer-text-color;
  padding: 40px 0 0;
  .footer-main {
    h2, h3, h4, .h2-replace, .h3-replace, .h4-replace  {
      text-transform: uppercase;
      font-size: 1.25rem;
      margin-bottom: 12px;
      color: $footer-title-color;
    }
  }
  .footer-bottom {
    background: $wrapper-background;
    font-size: 90%;
    padding: 15px 0;
    .copyright-payment {
    }
  }
  .footer-about-us {
    @include media-breakpoint-up(lg) {
      padding-right: 7%;
    }
    .fa, .material-icons {
      width: 20px;
      color: $link-color;
    }
      p:not([class]) {
          position: relative;
          margin-left: 22px;
          .fa {
              position: absolute;
              margin-left: -20px;
             padding-top: 6px;
          }
      }
  }
  .footer-menu, .block-newsletter {
    @include media-breakpoint-up(lg) {
      padding-left: 5%;
    }
  }
  .footer-menu {
    margin-bottom: 15px;
    ul {
      margin-bottom: 20px;
    }
  }
  .hook-display-footer {
    ul {
      margin-bottom: 20px;
    }
  }

  .footer-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    > a {
      font-size: 0;
      line-height: 0;
      margin-right: 0.5rem;
    }

    .fa {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      color: white;
      border-radius: 50%;
      font-size: 1rem;
    }

    .fa-facebook {
      background-color: #4267B2;
    }

    .fa-linkedin {
      background-color: #0072b1;
    }
  }
}

#scrollTopButton {
  display: none;
  position: fixed;
  right: 3%;
  bottom: 50px;
  text-align: center;
  opacity: 0.5;
  transition: all 0.3s;
  z-index: 15;
  .scroll-button {
    display: block;
    width: 40px;
    height: 40px;
    border: 2px solid;
    line-height: 36px;
    font-size: 25px;
    color: $light-text-color;
    background-color: $icon-color;
    background-image: url('../img/bg-line.png');
    .fa, .material-icons {
      position: absolute;
      left: 0;
      right: 0;
      top: 10px;
      text-align: center;
      transition: all 0.3s;
    }
  }
  &:hover {
    opacity: 1;
    .fa, .material-icons {
      top: 8px;
    }
  }
}

.toggle-linklist-title {
  position: relative;
  cursor: pointer;
  margin-top: 8px;
  &:after {
    content: "";
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: transparent;
    border-top-color: inherit;
    border-bottom-color: inherit;
    position: absolute;
    right: 0;
    top: 8px;
  }
}

.toggle-block-title {
  position: relative;
  cursor: pointer;
  transition-delay: 0s;
  &:after {
    content: "";
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: transparent;
    border-top-color: inherit;
    border-bottom-color: inherit;
    position: absolute;
    right: 15px;
    top: 20px;
  }
  &.is-hide {
    margin-bottom: -15px;
    border-bottom-color: transparent;
    transition-delay: 0.4s;
  }
}


/*** TYPOGRAPHY ***/
.banner {
  a img {
    transition: all 0.3s;
    &:hover {
      filter: brightness(75%);
    }
  }
}
.banner1 {
  overflow: hidden;
  text-align: center;
  max-width: 370px;
  margin: 0 auto 15px;
  img {
    width: 100%;
    transition: all 0.3s;
    &:hover {
      filter: brightness(75%);
    }
  }
  span:not(.btn) {
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    float: left;
    color: $title-color;
    text-transform: uppercase;
    margin-top: (10px + $base-padding);
  }
  span.btn {
    float: right;
    margin-top: 10px;
  }
  @include media-breakpoint-between(md, lg) {
    span:not(.btn) {
      width: 100%;
      float: none;
    }
    span.btn {
      float: none;
    }
  }
}
.banner2 {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  a {
    position: relative;
    display: block;
    &:before {
      content: "";
      position: absolute;
      border: 1px solid $link-hover-color;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      visibility: hidden;
      transition: all 0.3s;
    }
    &:hover:before {
      visibility: visible;
      left: 10px;
      top: 10px;
      right: 10px;
      bottom: 10px;
    }
  }
}
.banner3 {
  margin-bottom: 20px;
  position: relative;
  &:hover {
    background-color: $link-color;
    img {
      opacity: 0.8;
    }
  }
  a.btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translatey(-50%);
    min-width: 100px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  }
  &:hover a.btn {
    visibility: visible;
    opacity: 1;
  }
}
.banner4 {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-attachment: fixed;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    .layout-full-width &,
    .outside-container & {
      left: -100%;
      right: -100%;
      @if $center-enable-background {
        left: -$center-padding;
        right: -$center-padding;
      }
    }
  }
  .outer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .content {
    width: 100%;
    text-align: center;
  }
  h1, h2, h3, .h1-replace, .h2-replace, .h3-replace {
    font-size: 30px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  p {
    margin: 0 auto 20px;
  }
}


.banner5 {
  position: relative;
  z-index: 1;
    min-height: 150px;
  //margin-bottom: 15px;
      box-shadow: 0px 1px 5px $box-shadow-color;
      overflow: hidden;
     @include media-breakpoint-down(sm) {
         margin-bottom: 10px;
    }
        .block-text {
            h2, .h2-replace {
                padding-bottom: 5px ;
                border-bottom: 3px solid;
                font-family: 'Heebo', sans-serif;
                font-weight: 900;
                line-height: 1em;
                display: inline-block;
                width: 100%;
                padding-top: 25%;
                font-size: 2em;
                
            } 
            h3, .h3-replace  {
                    font-size: 0.9em;
                
                }
                 h2,h3, .h2-replace,.h3-replace{
                line-height: 1em !important;
                text-shadow: 0 0 9px #000; 
                text-transform: uppercase;
                      color: inherit;
                }
            
            @include media-breakpoint-only(md) {
                    h2, .h2-replace {
                        font-size: 1em;
                        padding-top: 5%;
                        border-bottom: 1px solid;
                    }
                    h3, .h3-replace {
                        font-size: 0.8em;
                    }
            }
            @include media-breakpoint-only(lg) {
                    h2, .h2-replace {
                        font-size: 1.3em;
                    }
                    h3, .h3-replace {
                        font-size: 1em;
                    }
                }
            @include media-breakpoint-down(sm) {
                    h2, .h2-replace {
                        font-size: 1em;
                        border-bottom: 1px solid;
                    }
                    h3, .h3-replace {
                        font-size: 0.7em;

                    }
                .btn {
                    font-size: 0.8rem;
                    padding: 2px 5px;
                    line-height: normal;
                    }
                }
            text-align: center;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: 5%;
            padding: 5%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            color: $color_white;
            z-index: 9;
            transition: all 0.3s;
            border: solid 1px #fff;
            &:after {
                background: #000;
                z-index: -9;
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.1;
                transition: transform 0.4s;
                
            }
        }
    &:hover {
        .block-text {
            margin: 0;
            padding: 10%;
            
            color: $color_cta;
                &:after {
                   width: 95%;
                    height: 95%;
                opacity: 0.8;
                transform: rotate(-180deg);
                    background: #fff;
            }
            h2,h3, .h2-replace,.h3-replace {
                text-shadow: 0 0 9px #fff; 
            }
        }
    }
    }
.row {
    div:nth-child(4n+1) {
        .banner5:hover .block-text {
            color: #04a5d9;
        }
        .banner5:hover .btn, .banner5 {
        background: linear-gradient(to right, #04a5d9 0%, #01c7ee 100%);
        }
    }
    div:nth-child(4n+2) {
        .banner5:hover .block-text {
            color: #394f1e;
        }
        .banner5:hover .btn, .banner5 {
        //background: #394f1e;
        background: linear-gradient(to right, #394f1e 0%, #759e26 100%);
        }
    }
    div:nth-child(4n+3) {
        .banner5:hover .block-text {
            color: #be5e0a;
        }
        .banner5:hover .btn, .banner5  {
    background: linear-gradient(to right, #be5e0a 0%, #ec990f 100%);
    }
    }
    div:nth-child(4n+4) {
        .banner5:hover .block-text {
            color: #b31426;
        }
        .banner5:hover .btn, .banner5  {
    background: linear-gradient(to right, #b31426 0%, #e11c2d 100%);
    }
    }
}
.feature, .feature2 {
  margin-bottom: 15px;
  padding: 20px 5%;
  text-align: center;
  //border: 1px solid $border-color;
  //border-radius: 5px;
  background: $box-background;
  transition: all 0.3s;
  height: calc(100% - 15px);
  box-shadow: 0px 1px 5px $box-shadow-color;
  .material-icons, .fa {
    font-size: 35px;
  }
  a {
    display: block;
    font-size: 1.125rem;
    margin-top: 15px;
    color: inherit;
    line-height: 1.4em;
    text-align: center;
  }
}
.feature {
  .material-icons, .fa {
    color: $link-color;
  }
  a:hover {
    color: $link-hover-color;
  }
}
.feature2 {
  .material-icons, .fa {
    color: $link-hover-color;
  }
  a:hover {
    color: $link-color;
  }
}

.typo {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6,
.h1-replace, .h2-replace, .h3-replace, .h4-replace, .h5-replace, .h6-replace{
    line-height: initial;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  ul {
    margin-bottom: 1rem;
    list-style: initial;
    padding-left: 15px;
  }
  ol {
    padding-left: 15px;
  }
  ul, ol {
    li +li {
      padding-top: 5px;
    }
  }
  li > ul {
    margin-top: 5px;
  }
  ul.linklist {
    list-style: none;
    padding-left: 0;
  }
  blockquote, .blockquote {
    padding: .25rem 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    border-left: 4px solid $border-color;
  }
  pre code {
    white-space: pre-wrap;
  }
  iframe {
    max-width: 100%;
  }
}

.js-dropdown-toggle {
  .dropdown-title {
    display: block;
    position: relative;
    cursor: pointer;
    &:after {
      content: "\E145";
      font-family: 'Material Icons';
      font-weight: 700;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .dropdown-content {
    display: none;
  }  
}


/*** BOXED LAYOUT ***/
.boxed-layout {
  margin: auto;
  margin-top: 25px;
  margin-bottom: 50px;
  max-width: $container-xs-max-width;
  padding: 0 5px;
  @include media-breakpoint-up(sm) {
    max-width: map-get($container-max-widths, sm);
  }
  @include media-breakpoint-up(md) {
    max-width: map-get($container-max-widths, md);
  }
  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg);
  }
  @include media-breakpoint-up(xl) {
    max-width: map-get($container-max-widths, xl);
  }

  #index & #wrapper {
    overflow-x: initial;
  }
  .banner4 {
    .background {
      left: ($grid-gutter-width / -2);
      right: ($grid-gutter-width / -2);
      @include media-breakpoint-down(md) {
        left: ($grid-gutter-width-small / -2);
        right: ($grid-gutter-width-small / -2);
      }
    }
  }
}

.google-map-iframe {
  iframe {
    width: 100%;
  }
}

.cookie-message {
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  z-index: 99999999;
  display: none;
  .cookie-message-wrapper {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cookie-message-content {
    font-size: 1rem;
    padding: 15px;
    p {
      margin-bottom: 0;
    }
    a {
      opacity: 0.8;
      color: inherit;
      text-decoration: underline;
      transition: all .2s ease-in-out;
      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }
    @include media-breakpoint-down(md) {
      font-size:  0.875rem;
      padding: 10px;
    }
  }
  .cookie-close-button {
    display: block;
    padding: 15px 3%;
    font-size: 0.9rem;
    font-weight: 700;
    border-radius: 0;
    background: #14a7d0;
    color: #ffffff;
    &:hover {
      background: #3acdf6;
    }
    @include media-breakpoint-down(md) {
      font-size:  0.875rem;
      padding: 10px;
    }
  }
}

/*** MOBILE TOUCH SCREEN ***/
body.touch-screen {
  .banner4 .background {
    background-attachment: initial;
    background-size: initial;
    background-position: center center;
  }
  .banner2 a:before {
    display: none;
  }
  .banner3 a.btn {
    visibility: visible;
    opacity: 1;
  }
  .banner3:hover img {
    opacity: 1;
  }
  .main-product-details .product-cover .zoomWrapper {
    pointer-events: none;
  }
  .zoomContainer {
    display: none;
  }
  .pace {
    .pace-progress {
      background: transparent;
    }
    .pace-progress-inner {
      box-shadow: none;
    }
  }
}

/* loading.io */
.uil-spin-css {
  display: block;
  background: none;
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
}
@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.uil-spin-css > span {
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}
.uil-spin-css > span > span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #000;
}
.uil-spin-css > span:nth-of-type(1) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}
.uil-spin-css > span:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(2) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  animation-delay: 0.12s;
}
.uil-spin-css > span:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(3) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.uil-spin-css > span:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(4) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.37s;
  -moz-animation-delay: 0.37s;
  -webkit-animation-delay: 0.37s;
  -o-animation-delay: 0.37s;
  animation-delay: 0.37s;
}
.uil-spin-css > span:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(5) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.uil-spin-css > span:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(6) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.62s;
  -moz-animation-delay: 0.62s;
  -webkit-animation-delay: 0.62s;
  -o-animation-delay: 0.62s;
  animation-delay: 0.62s;
}
.uil-spin-css > span:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(7) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.uil-spin-css > span:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(8) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  animation-delay: 0.87s;
}
.uil-spin-css > span:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

form {
    font-size: 0.9em;
        .PM_ASCriterionCheckbox {
        vertical-align: top;
        margin-top: 5px;
    }
    label {
        //width: 92%;
        width: 100%;
        text-align: left;
        text-transform: none !important;
    }
    .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckboxSelected .PM_ASLabelLink,
    .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox .PM_ASLabelLink:hover {
        font-weight: normal;
        color: $color_cta;
    }
    .PM_ASCriterionGroupLink .PM_ASCriterionLinkSelected,
    .PM_ASCriterionGroupLink .PM_ASCriterionLink:hover {
        font-weight: normal;
        color: $color_cta;
    }
    .PM_ASCriterionHideToggleLink,
    .PM_ASCriterionGroupLinkColor .PM_ASCriterionLink {
        font-size: inherit;
        font-weight: 500;
    }
}
@include media-breakpoint-down(sm) { 
    .grid .product-miniature .informations-section, .grid .product-miniature .first-prices, .grid .product-miniature .second-prices {
             width: auto; 
    }
}
#customer-form .form-control {
        height: inherit;
    }
#index .title-block {
    font-size:  1.5em;
}