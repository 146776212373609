.block-reassurance {
  li + li {
    border-top: 1px dashed $border-color;
  }
  .block-reassurance-item {
    padding: $box-padding;
    display: flex;
    align-items: center;
    img {
      width: 25px;
      height: auto;
      margin-right: 10px;
    }
  }
}
