@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.row:before, .row:after {display: none !important;}

#header{
    background: #fff;
    transition: 250ms all;
    .header-logo{
        img{
            transition: max-width 0.5s;
        }
        
    }
    .header-banner {
//            background: #ea5573;
            background: $color_cta;
            color: #fff;
        }

.desktop-sticky-wrapper {
    //min-height: 146px;
    height: auto !important;
    &.is-sticky {  
        .header-right {
          margin: 3px 0;
        }
        .main-header {
          padding-top: 0px;
          padding-bottom: 0px;
          .header-logo{
              img{
                  max-width: 200px;
              }
          }
        }
    }
  }
} 
.header-sticky {
    z-index: 9999 !important;
    width: 100% !important;
    background: #fff;
}


#footer{
    .footer-main{
        .hook-display-footer{
            display: flex;
            justify-content: center;
            > div{
                width: 25%;
            }
        }
    }
    .footer-bottom{
        text-align: left;
        p{
            margin: 0;
        }
    }
    @include media-breakpoint-down(sm) {
        .sld-home-footer{
            display:none;
        }
    }
}

.sld-reassurances{
    a {
        display: flex;
        margin-top: 0;
        justify-content: space-between;
            span {
                text-align: left;
                font-size: 0.9rem;
                padding-left: 20px;
                align-self: center;
                flex: content;
            }
        img {
            
            @include media-breakpoint-down(md) {
                min-width: 83px;
            }
        }
    }
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    > div{
//        text-align: center;
//        align-self: baseline;
//        a{
//            display: flex;
//            align-items: center;
//            margin-top: 0;
//            //flex-direction: column;
//            //justify-content: center;
//            img{
//                width: auto;
//                height: auto;
//                    max-width: 30%;
//                    align-self: end;
//            }
//            span {
//                padding-top: 10px;
//                padding-left: 10px;
//                text-align: left;
//                line-height:normal;
//                font-size: 0.9rem;
//                align-self: baseline;
//            }
//        }
//        
//    }
}

.pfg-forms{
    border: 1px solid#c7c7c7;
    border-radius: 5px;
    padding: 20px;
    background:#ffffff;
    h3{
        display: none;
    }    
    div.form-group{
        display: flex;
    }
    div.submit { 
        justify-content: center;
    }
}
.page-cms{
    h1{
        margin-bottom: 3rem;
        text-transform: uppercase;
    }
    h2{
        margin: 2rem 0 2rem;
        text-transform: uppercase;
    }
    h3{
        margin: 2rem 0 1rem;
        text-transform: uppercase;
    }
    table td{
        padding: 1rem;
    }
}
.hide{
    display: none;
}
.cart-detailed-actions a.btn{
    width: 100%;
}
#payment-confirmation{
    text-align: center;
}
#print-quotation #customer-information {
    padding: 0;
    margin-bottom: 1rem;
   .form-footer button {
        width: 100%;
    }
}
#print-quotation .card-block  {
   padding: 0;
}
b,
strong {
    font-weight: 400;
}
body#cms, 
body#category, 
body#index  {
    b,
    strong {
    font-weight: 700;
    }
}
.product-list .product-flags-manufacturer{
    position: absolute;
    top: 5px;
    right: 6px;
    width: 3.5rem;
    img{
        width: 100%;
    }
}
.PM_ASCriterionsGroupPrice{
    .PM_ASCriterionStepEnable{
        .row{
            justify-content: space-between;
            margin: 0;
            text-align: center;
            > div{
                width: calc(50% - 0.5rem);
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; 
                }
                input{
                    -moz-appearance:textfield; 
                }
                .input-group-addon{
                    font-weight: bold;
                    padding-left: 0.5rem;                   
                    line-height: 2rem;
                }
            }
        }
    }
}
.h1-replace, .h2-replace, .h3-replace, .h4-replace, .h5-replace, .h6-replace {
      font-weight: 500;
    letter-spacing: 0.02em;  
    margin-bottom: 0.5rem;
    line-height: inherit;
}
.h4-replace {
    font-size: 1.35rem;
}
.h3-replace {
    font-size: 1.575rem;
}
div.st-menu, 
div.st-menu-right{ 
    z-index: 10000;
}
#index .sld-reassurances .feature,
#index .sld-reassurances .feature2{
    background: none;
    box-shadow: none;
}
#gdpr_consent{
    .custom-checkbox{
        margin: 0;
    } 
}
.js-subscription-form .custom-checkbox-wrapper .row{
    max-width: 100%;
    flex: 0 0 100%;
    .custom-checkbox{
        max-width: 2rem;
        flex: 0 0 2rem;
    }
    .psgdpr_consent_message{
        max-width: calc(100% - 2rem);
        flex: 0 0 calc(100% - 2rem);
    }
}
div.st-menu-close{
    top: 0;
}
.cart-darique{
    margin-top: 2rem;
    padding: 1rem 2rem;
    .card-block{
        padding: 0;
        text-transform: uppercase;
        font-size: 1.25rem;
        color:#00658b;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    #dariqueWrapper{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;
        .dariqueRuleContainer{
            width: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: column;
            background:#f7f7f7;
            border: 1px solid #c7c7c7;
            margin-bottom: 2rem;
            border-radius: 5px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            padding: 1rem;
            .page-heading{
                margin: 0;
                text-align: center;                
                line-height: 1rem;                
                padding: 0;
            }
            ul{
                margin: 0;
                width: 100%;
                > div{
                  > div {
                    height: 100%;
                  }
                }
                li{
                    height: 100%;
                    .gift{
                        display: flex;
                        flex-direction: column;
                        border: none;
                        background: #fff;
                        padding: 1rem;
                        height: 100%;
                        select{
                            margin: 1rem 0;
                            font-size: 0.75rem;
                            font-weight: lighter;
                        }
                        h5{
                            font-size: 0.75rem;
                            flex: 1;
                        }
                        a{
                            font-size: 0.75rem;
                            padding:  0.25rem 0.5rem;
                        }
                        &:hover{
                            background-color: #fff;
                        }
                    }
                }
            }
            .arrow_left{            
                border: 15px solid transparent;
                border-right-color: #00658b;
                top: calc(50% - 8px);
            }
            .arrow_right{
                border: 15px solid transparent;
                border-left-color: #00658b;
                top: calc(50% - 8px);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        #dariqueWrapper .dariqueRuleContainer{
            width: 100%;
        }
    }
}

// # visual composer prestashop # //
#cms {
    .breadcrumb-wrapper {
        margin-bottom: 0;
    }
    #main .page-content {
        padding: 0;
    }
    #main .page-content {
        background: inherit;
    }
}
.vc_row {
    .product-style .product-container {
        box-shadow: none;
    }
    article {
        position: relative;
        overflow: hidden;
        .product-thumbnail {
            overflow: hidden;
        }
        img {
            transition: 1s transform;
        }
        .buttons-sections {
            position: absolute;
            top: 50%;
            left: -100%;
            transition: 0.5s all;
        }
        &:hover {
            img {
                transform: scale(1.4);
            }
            .buttons-sections {
                left: 20%;
            }
        }
        .product-name {
            padding: 0;
        }
        h3 {
            text-align: center;
            margin: 0;
        }
    }
    article .second-block-wrapper {
        .informations-section, .grid-buy-button {
            display: none;
        }
        .add-to-cart-button {
            
        }
    }
    .position-relative {
        position: relative;
    }
    .position-absolute {
        position: absolute;
        z-index: 999;
        background: rgba($color_white, 0.5);
        padding: 5%;
        color: $color_cta;
        font-weight: 400;
        font-size: 18px;
        @include media-breakpoint-down(md) {
            margin: 5% !important;
        }
    }
    .text-lookbook {
        color: $color_cta;
        font-weight: 400;
        font-size: 16px;
        h1 {
            color: $color_cta;
            margin-bottom: 1rem !important;
            @include media-breakpoint-up(md) {
                    margin-left: -250px;
                    background:  $color_white;
                    box-shadow: 10px 10px 0px $color_cta;
                    padding: 20px;
                }
        }
    }
    
}
#category{
    .sld-category-breadcrumb{
        //defaut
        background-color: #0097CD;
        .breadcrumb-wrapper{
            margin-bottom: 0px;
            background-color: inherit;
            font-size: 12px;
            color: #ffffff;
            text-transform: uppercase;
            .breadcrumb{
                padding: 10px 0px;
                a:hover{
                    color: #00658b;
                }
            }
            .fa-home{
                font-size: 20px;
                float: left;
            }
        }
    }
    .sld-category-cover {
        display: block;
        width: 100%;
        height: auto;
        overflow: hidden;
        position: relative;
        padding:20px 0px;
        //defaut
        background-color: #0097CD;
        //background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 1;
        background-size: 2000px 400px;
        background-position: top center;
        margin-bottom: -25px;
        @media (min-width: 2000px) {
            //background-size: 100% auto;
        }
        @include media-breakpoint-down(md) {
            background-image: none!important;
            padding:0px 0px 10px;
        }
        &:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #464646;
            opacity:0.45;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        &.nofilter{
            &:after{
                display: none;
            }
        }
        h1{
            text-transform: uppercase;
            color: #ffffff;
            font-size: 35px;
            margin-bottom: 5px;
            line-height: 40px;
            position: relative;
            @include media-breakpoint-down(md) {
                padding-top: 20px;
                &:after{
                    content:'';
                    position: absolute;
                    width: 60%;
                    top: 0;
                    left: 0;
                    height: 1px;
                    background-color: #ffffff;
                }
            }
        }
        h2{
            text-transform: uppercase;
            color: #ffffff;
            font-size: 15px;
            position: relative;
            margin-bottom: 15px;
            font-weight: 300;
        }
        .description{
            color: #ffffff;
            position: relative;
            padding-top: 15px;
            &:after{
                content:'';
                position: absolute;
                width: 30%;
                top: 0;
                left: 0;
                height: 1px;
                background-color: #ffffff;
            }
            a{
                color: #ffffff;
                text-decoration: underline;
            }
        }
    }
    #content_after_list{
        padding-top: 30px;
    }
}
.row-left-center-right {
    padding-top: 50px;
}

@include media-breakpoint-down(md) {
    .left-categories{
        padding: 0 15px 0px;
        .column-title{
            padding: 0.75rem 1.25rem;
            font-size: 0.9rem;
            line-height: 1.5rem;
            font-weight: 300;
            margin: 0px -15px;
            border-bottom: none;
            position: relative;
            &:after{
                content: "";
                font-family: "Material Icons";
                padding-right: 5px;
                font-size: 24px;
                font-weight: 400;
                position: absolute;
                right: 0;
            }
        }
        .category-tree{
            display: none;
        }
        &.sldactive{
            padding: 0 15px 15px;
            .column-title{
                margin: 0 -15px 15px;
                border-bottom: 1px solid #c7c7c7;
                &:after{
                    content: "";
                }
            }
            .category-tree{
                display: block;
            }
        }
    }

    //FILTRE
    .PM_ASMobileMode{
        .card{
            background-color: #00658B;
            color: #ffffff;
        }
        .card-block{
            background-color: #ffffff;
            color: #5d5d5d;
        }
    }
}
@include media-breakpoint-up(md) {
    #references_table_container{
        .table-responsive{
            overflow-x: initial;
        }
    }
}

#header{
    #trustpilot_header{
        padding-top: 6px;
        #tp-widget-wrapper{
            text-align: left!important;
        }
    }
}

#index .static-html h1{
    font-size: 21.6px;
}

.cart-preview .total-line.shipping, .cart-preview .cart-total.total-line {
    display:none; 


}

#hint {
cursor: pointer;
background: #006990;
color: #FFF;
margin-left: 5px;
border-radius: 50%;
width: 20px;
display: inline-block;
text-align: center;
height: 20px;
line-height: 20px;
font-size: 14px;
}

.tooltip-delivery{
    margin:8px;
    padding:8px;
    border:1px solid blue;
    background-color:yellow;
    opacity: 1 !important;
    position: absolute;
}

picture {
    display:block;
}

.sld-product-custom {
    text-align: center;
    .product-container {
        transition: all 0.3s;
        background-color: #ffffff;
        border: none;
        padding: 0;
        border-radius: 1px;
        box-shadow: none;
        margin-bottom: 20px;
    }

    .sld-color-button {
        display: inline-block;
        border: none;
        line-height: 1;
        background-color: #e4284e;
        color: #fff;
        text-align: center;
        transition: all .5s;
    }

    .product-name h3 {
        line-height: 10px;
    }

    .add-to-cart-button {
        text-align: center;
    }

    .product-price-and-shipping.d-flex.flex-wrap.align-items-baseline {
        justify-content: center;
        padding-bottom: 15px;
    }

    .product-name {
        min-height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .add-to-cart-button {
        background-color: #e4284e;
    }
}

.sld-home-footer .elementor-heading-title {
    font-family: "Montserrat", montserrat!important;
    font-size: 15px;
    font-weight: 800!important;
    color: #00698f!important;
    text-transform: uppercase;
}

.sld-home-footer h2.elementor-heading-title.elementor-size-default {
    text-align: left!important;
}

.sld-home-footer .elementor-element.elementor-widget.elementor-widget-heading {
    margin-bottom: 10px;
}

.sld-home-footer .elementor-widget-container {
    color: #545454;
    font-family: "Montserrat", montserrat;
    font-size: 12px;
    font-weight: 500;
}

.sld-home-footer .elementor-element.elementor-widget.elementor-widget-text-editor {
    line-height: 1.3rem;
}
