.contact-rich {
  h4 {
  }
  hr {
    border-style: dashed;
  }
  .block {
      &:first-child {
          padding-top: 20px;
      }
    display: flex;
    .icon {
      width: 3rem;
      i {
        font-size: 1.5rem;
      }
    }
    .data {
        flex: 100%;
    }
  }
}
.contact-form {
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    padding: 20px;
    background: $color_white;
    .form-control-label {
        text-align: right;
        @include media-breakpoint-down(sm) { 
            text-align: left;
        }
    }
}
