@import "_block-reassurance";
@import "_imageslider";
@import "_mainmenu";
@import "_search-widget";
@import "_featuredproducts";
@import "_ps_socialfollow";
@import "_ps_emailsubscription";
@import "_ps_sharebuttons";
@import "_aeuc_front";

.currency-selector-wrapper, .language-selector-wrapper {
}
.currency-selector-wrapper + .language-selector-wrapper, .language-selector-wrapper + .currency-selector-wrapper {
  margin-left: 5px;
}
.currency-selector, .language-selector {
  .dropdown-current {
    padding: 5px 8px;
    background-color: $color_gray;
    border-radius: 3px;
    @include media-breakpoint-down(sm) {
      padding: 3px 5px;
    }
  }
  .dropdown-icon {
    .material-icons, .fa {
      font-size: 15px;
    }
  }
  .dropdown-menu {
    min-width: 0;
  }
  .dropdown-item {
    @include media-breakpoint-down(sm) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
.language-selector {
  #language-selector-label {
    display: none;
  }
  .l-code {
    display: none;
  }
  .l-name {
    display: flex;
  }
  img {
    margin-top: -2px;
  }
}
.currency-selector {
  #currency-selector-label {
    display: none;
  }
  .dropdown-menu {
  }
  .c-sign {
    display: inline-block;
  }
}
.sidebar-currency {
  border-top: 1px solid $border-color;
  padding: 20px 10px;
  .currency-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 0 3px;
    }
    .dropdown-item {
      border-radius: 5px;
      padding: 5px 8px;
    }
    .current .dropdown-item {
      background: $color_green;
      color: $light-text-color;
    }
  }
  .c-sign {
    display: none;
  } 
}
.sidebar-language {
  border-top: 1px solid $border-color;
  padding: 20px 10px;
  .l-name {
    display: none;
  }
  .language-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 0 3px;
    }
    .dropdown-item {
      border-radius: 5px;
      padding: 5px 8px;
      text-transform: uppercase;
      min-width: 40px;
      text-align: center;
    }
    .current .dropdown-item {
      background: $color_green;
      color: $light-text-color;
    }
  }
}

.user-info {
  text-align: center;
  .account-link {
   
    > a {
      display: flex;
      align-items: center;
         border: 2px solid;
    border-radius: 3px;
      padding: $base-padding-border2px 10px;
      font-size: 0.875rem;
      white-space: nowrap;
      i {
        font-size: 17px;
        margin-left: -2px;
        margin-right: 2px;
        margin-top: 0;
      }
    }
  }
  .logout-link {
    display: none;
  }
  .dropdown-customer-account-links {
    position: absolute;
    top: 110%;
    right: 0;
    min-width: 100%;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    .dropdown-menu {
      display: block;
      width: 100%;
      position: initial;
      border: 2px solid;
      border-radius: 3px 0 3px 3px;
    }
    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 3px;
      i, span {
        margin: 0 5px;
      }
      i {
        font-size: 22px;
      }
    }
    .logout {
      padding: $base-padding;
      text-align: center;
      color: red;
      border-top: 1px solid $border-color;
      i {
        min-width: 18px;
      }
    }
  }
  .customer-logged {
    position: relative;
    &:hover, .hover {
      .dropdown-customer-account-links {
        visibility: visible;
        opacity: 1;
        top: calc(100% - 2px);
        transition: all 0.3s;
      }
    }
  }
}
.customer-signin-module {
  @include media-breakpoint-down(sm) {
    //position: absolute;
    //right: 0;
    //top: -33px;
      span {
          display: none;
      }
    .user-info {
      .account-link {
        border-width: 1px;
        > a {
        padding: 0;
        height: $base-height;
        width: $base-height;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
            
//          padding: 5px 6px;
//          font-size: inherit;
//          line-height: normal;
          i {
            font-size: 17px;
            margin-right: 0;
          }
        }
      }
      .dropdown-customer-account-links {
        display: none;
      }
    }
  }
}

.sidebar-account {
  padding: 20px 10px;
  border-top: 1px solid $border-color;
  background: $color_gray;
  &.user-info {
    > ul >li {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .account-link {
    }
    .logout-link {
      display: block;
      color: red;
    }
    .dropdown-customer-account-links {
      display: none;
    }
  }
}
.sidebar-header-phone {
  padding: 15px 5px;
  border-top: 1px solid $border-color;
  background: $color_gray;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      white-space: nowrap;
      padding: 8px 12px;
    }
  }
}
.st-menu-title {
  background: $color_gray;
}
.sidebar-menu {
  padding: 15px;
  border-top: 1px solid $border-color;
}
.sidebar-categories {
  .category-tree {
    .category-sub-menu {
      display: none;
    }
    .collapse-icons {
      right: -8px;
    }
  }
}


.cart-preview {
  position: relative;
  .cart-header {
    text-align: center;
    .cart-link {
      display: block;
      border-radius: 3px;
      padding: $base-padding 10px;
      font-weight: 700;
      font-size: 0.875rem;
      position: relative;
      white-space: nowrap;
      transition: all 0.3s;
    }
    i {
      font-size: 17px;
      margin-left: -2px;
      margin-right: 2px;
    }
    .cart-products-count {
      //display: block;
      align-items: center;
      display: flex;
      text-align: center;
      min-width: 18px;
      height: 18px;
      //line-height: 18px;
      padding: 4px;
      background-color: $color_pink;
      border-radius: 50%;
      font-size: 10px;
      position: absolute;
      top: -6px;
      left: 15px;
      transition: all 0.3s;
    }
  }
  .cart-dropdown {
    position: absolute;
    top: 45px;
    right: 0;
    width: 300px;
    padding: 0;
    z-index: 999;
    background: $light-background;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 2px 4px $box-shadow-color;
    border-radius: 5px;
    overflow: hidden;
  }
  .cart-title {
    background: $color_gray;
    padding: 15px 5px;
    border-bottom: 1px solid $border-color;
    h3, h4,.h3-replace, .h4-replace {
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 1rem;
      text-align: center;
    }
  }
  .cart-items {
    padding: 5px 10px;
  }
  .cart-product-line {
    padding: 12px 0;
    display: flex;
    align-items: center;
    + .cart-product-line {
      border-top: 1px dashed $border-color;
    }
    .product-image {
      flex: none;
      max-width: 80px;
    }
    .product-infos {
      width: 100%;
      padding-left: 8px;
      align-self: flex-start;
    }
    .remove-from-cart {
      flex: none;
      font-size: 14px;
    }
    .product-name {
      clear: both;
      display: inline-block;
      padding: 0px;
    }
    .product-attributes {
      display: block;
      font-style: italic;
    }
    .product-price-quantity {
      display: flex;
      margin-top: 9px;
    }
    .product-price {
      font-size: 1rem;
      font-weight: 700;
    }
    .x-character {
      display: inline-block;
      width: 12px;
      text-align: center;
    }
    .product-quantity {
      display: none;
      .input-group {
        .input-group-btn > .btn {
          width: 25px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        }
        input {
          width: 40px;
          height: 25px;
          line-height: 25px;
        }
      }
    }
  }
  .allow-update-quantity .cart-product-line {
    .product-price-quantity {
      justify-content: space-between;
      align-items: center;
    }
    .product-quantity {
      display: block;
    }
    .x-character, .product-qty {
      display: none;
    }
    .remove-from-cart {
      align-self: normal;
      margin-top: 21px;
    }
  }

  .cart-bottom {
    padding: 10px 15px;
    background: $color_gray;
    border-top: 1px solid $border-color;
  }
  .total-line {
    padding: 5px 0 0;
    display: flex;
    .value {
      margin-left: auto;
    }
  }
  .cart-subtotals {
    .total-line .value {
      font-size: 0.9rem;
    }
  }
  .cart-total {
    label, .label {
      text-transform: uppercase;
    }
    .tax-short {
      display: none;
    }
  }
  .cart-tax {
    padding-top: 0;
    display: none;
  }
  .cart-action {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .no-items {
    padding: 15px;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    &:hover, &.hover {
      .cart-header {
        .cart-products-count {
          top: 9px;
        }
      }
      .cart-dropdown {
        visibility: visible;
        opacity: 1;
        top: 35px;
        transition: all 0.3s;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .cart-header {
      .cart-link {
        padding: 0;
        height: $base-height;
        width: $base-height;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .material-icons, .fa {
        margin: 0;
      }
      .cart-total-value {
        display: none;
      }
      .cart-products-count {
        left: 50%;
        margin-left: -8px;
      }
    }
  }
}


.left-brand-list, .left-supplier-list {
  .linklist {
    margin-top: -3px;
  }
}

.mailalert-product-page {
  .mailalert-form {
    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .alert-email {
    width: 100%;
    margin-right: 3%;
  }
  .alert-button {
    .btn {
      text-transform: none;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .mailalert-msg {
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    .mailalert-form {
      flex-direction: column;
    }
    .alert-email {
      width: 100%;
    }
    .alert-button {
      margin-top: 5px;
      margin-left: 0;
    }
  }
}

.my-mail-alerts {
  padding-top: 0;
  padding-bottom: 0;
  .mail-alert-line {
    padding: $box-padding 0;
    + .mail-alert-line {
      border-top: 1px solid $border-color-lighten;
    }
    .p-image img {
      max-width: 80px;
    }
    .p-name {
      margin-left: 10px;
    }
    .p-remove {
      margin-left: auto;
    }
  }
}
